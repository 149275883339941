import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { OpportunityStatus } from 'src/app/types/crm/opportunity/OpportunityStatus';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class CRMServiceV2 {
  url = apiUrl + 'api/crm';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  getOpportunityStatuses() : Observable<OpportunityStatus[]> {
    return this.http.get<any>(`${this.url}/Opportunity/OpportunityStatus`)
    .pipe<OpportunityStatus[]>(map((res) => res.OpportunityStatuses));;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Document } from 'src/app/types/document/document';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root'
})
export class DocumentsPatriciaService {
  url = apiUrl + 'api';

constructor(private http: HttpClient) {

}

getPatriciaDocumentPreview(DocumentId: number) {
  let params = new HttpParams();
  params = params.append('DocumentId', DocumentId.toString());

  return this.http
    .get(`${this.url}/Documents/GetPatriciaDocumentPreview`,
      { responseType: 'blob', params: params }
    )
}

}

import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { DocumentsPatriciaService } from 'src/app/services/documents/documents-patricia.service';
import notify from 'devextreme/ui/notify';
import { PDFPreviewModule } from 'src/app/components/library/pdf-preview/pdf-preview.component';

@Component({
  selector: 'crm-clients-overview-documents',
  templateUrl: './crm-clients-overview-documents.component.html',
  styleUrls: ['./crm-clients-overview-documents.component.scss'],
})
export class CrmClientsOverviewDocumentsComponent implements OnInit, OnChanges {
  @Input() PatriciaNameId: number;

  url: string = environment.CalystaApiBaseURL;

  loadingVisible = false;
  previewDocumentVisible = false;
  previewedDocumentName: string = null;
  fileBlob: any;

  showHideEmailButtonText: string = 'Show Email';
  ShowEmails: boolean = false;

  DataSource: DataSource;

  constructor(private DocumentPatriciaSvc: DocumentsPatriciaService) {
    this.onPreview = this.onPreview.bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.PatriciaNameId) {
      this.createDataSource();
    }
  }

  createDataSource() {
    this.DataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'DocumentId',
        loadUrl: `${this.url}api/Documents/GetAllByClient`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = {
              ClientId: this.PatriciaNameId,
              ShowEmails: this.ShowEmails,
            };
          }
        },
      }),
    });
  }

  ngOnInit() {
    if (this.PatriciaNameId != null) this.createDataSource();
  }

  determineFileTypeLogo(Filetype: string): string {
    switch (Filetype) {
      case "pdf":
        return "fa-solid fa-file-pdf";
      case "doc":
        return "fa-solid fa-file-word";
      case "docx":
        return "fa-solid fa-file-word";
      case "xls":
        return "fa-solid fa-file-excel";
      case "xlsx":
        return "fa-solid fa-file-excel";
      case "ppt":
        return "fa-solid fa-file-powerpoint";
      case "pptx":
        return "fa-solid fa-file-powerpoint";
      case "eml":
        return "fa-solid fa-envelope";
      case "msg":
        return "fa-solid fa-envelope";
      default:
        return "fa-solid fa-file";
    }
  }

  // File Preview of the document.
  onPreview(e) {
    this.loadingVisible = true;
    this.DocumentPatriciaSvc.getPatriciaDocumentPreview(e.row.data.DocumentId).subscribe((data) => {
      this.fileBlob = data;
    }, (error) => {
      this.loadingVisible = false;
      notify('Error while fetching document preview', 'error', 3000);
    },
    () => {
      this.loadingVisible = false;
      this.previewDocumentVisible = true;
      this.previewedDocumentName = e.row.data.Description;
    });
  }

  showHideEmail() {
    this.ShowEmails = !this.ShowEmails;
    if (this.ShowEmails === false) {
      this.showHideEmailButtonText = 'Show Emails';
    } else {
      this.showHideEmailButtonText = 'Hide Emails';
    }
    this.createDataSource();
  }

}

@NgModule({
  imports: [CommonModule, DxFormModule, DxTextBoxModule, DxDataGridModule, DxPopupModule, PDFPreviewModule, DxLoadPanelModule, DxButtonModule],
  providers: [],
  exports: [CrmClientsOverviewDocumentsComponent],
  declarations: [CrmClientsOverviewDocumentsComponent],
})
export class CrmClientsOverviewDocumentsModule {}

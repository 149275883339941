  <div class="list" *ngFor="let list of OpportunityList; let listIndex = index">
    <div class="list-title">{{OpportunityStatuses[listIndex].OpportunityStatusLabel}} </div>
    <dx-scroll-view>
      <dx-sortable
          class="sortable-cards"
          group="cardsGroup"
          [data]="list"
          (onDragStart)="onTaskDragStart($event)"
          (onAdd)="onTaskDrop($event)">
        <div *ngFor="let item of list">
          <opportunity-card [opportunity]="item"></opportunity-card>
        </div>
      </dx-sortable>
    </dx-scroll-view>
  </div>

import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { Opportunity } from 'src/app/types/crm/opportunity/Opportunity';


@Component({
  selector: 'opportunity-card',
  templateUrl: './opportunity-card.component.html',
  styleUrls: ['./opportunity-card.component.scss']
})
export class OpportunityCardComponent implements OnInit {

  @Input() opportunity: Opportunity;

  constructor() { }

  ngOnInit() {
  }

}

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [OpportunityCardComponent],
  exports: [OpportunityCardComponent],
})
export class OpportunityCardModule {}

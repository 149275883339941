import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DxScrollViewModule, DxTabPanelModule } from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { PatriciaService } from 'src/app/services/patricia/patricia.service';
import { CrmClientsOverviewFinancialModule } from './crm-clients-overview-financial/crm-clients-overview-financial.component';
import { CrmClientsOverviewNotesModule } from './crm-clients-overview-notes/crm-clients-overview-notes.component';
import { CrmClientsOverviewDocumentsModule } from "./crm-clients-overview-documents/crm-clients-overview-documents.component";
import { CrmClientsOverviewDeadlinesModule } from './crm-clients-overview-deadlines/crm-clients-overview-deadlines.component';
import { CrmClientsOverviewGeneralModule } from "./crm-clients-overview-general/crm-clients-overview-general.component";
import { CrmClientsOverviewPortfolioModule } from './crm-clients-overview-portfolio/crm-clients-overview-portfolio.component';
import { CrmClientsOverviewOpportunitiesComponent, CrmClientsOverviewOpportunitiesModule } from './crm-clients-overview-opportunities/crm-clients-overview-opportunities.component';

@Component({
  selector: 'app-crm-clients-overview',
  templateUrl: './crm-clients-overview.component.html',
  styleUrls: ['./crm-clients-overview.component.scss']
})
export class CrmClientsOverviewComponent implements OnInit {

  @Input() PatriciaNameId: number;

  ClientName: string;

  constructor(private PatriciaSvc: PatriciaService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.PatriciaNameId = this.route.snapshot.params['id'];
    this.PatriciaSvc.getClientInformation(this.PatriciaNameId).subscribe((data) => {
      this.ClientName = data.LegalName;
    });
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxScrollViewModule,
    DxTabPanelModule,
    TitleBarModule,
    CrmClientsOverviewFinancialModule,
    CrmClientsOverviewNotesModule,
    CrmClientsOverviewDocumentsModule,
    CrmClientsOverviewDeadlinesModule,
    CrmClientsOverviewGeneralModule,
    CrmClientsOverviewPortfolioModule,
    CrmClientsOverviewOpportunitiesModule,
],
  providers: [],
  exports: [],
  declarations: [CrmClientsOverviewComponent],
})
export class CrmClientsOverviewModule {}

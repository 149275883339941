import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { DxScrollViewModule, DxSortableModule } from 'devextreme-angular';
import { DxSortableTypes } from 'devextreme-angular/ui/sortable';
import { OpportunityCardModule } from 'src/app/components/library/opportunity-card/opportunity-card.component';
import { CRMServiceV2 } from 'src/app/services/crm/crm.service';
import { Opportunity } from 'src/app/types/crm/opportunity/Opportunity';
import { OpportunityStatus } from 'src/app/types/crm/opportunity/OpportunityStatus';

@Component({
  selector: 'crm-clients-overview-opportunities',
  templateUrl: './crm-clients-overview-opportunities.component.html',
  styleUrls: ['./crm-clients-overview-opportunities.component.scss']
})
export class CrmClientsOverviewOpportunitiesComponent implements OnInit {

  @Input() ClientId: number;

  OpportunityStatuses: OpportunityStatus[] = [];

  OpportunityList: Opportunity[][] = [];

  Opportunities: Opportunity[] = [];

  constructor(private crmSvc: CRMServiceV2) { }

  ngOnInit() {

    this.Opportunities = [
      {
        Id: 1,
        ClientId: 1022,
        ProspectId: null,
        CreatedOn: new Date(),
        CreatedBy: 1,
        Description: 'Perform a search for a new application',
        OpportunityStatus: {
          OpportunityStatusId: 3,
          OpportunityStatusLabel: 'Quote sent',
          TimeLinePosition: 2,
        },
        ResponsibleEmployee: "MOKA",
        Supervisor: 1,
        Remarks: 'Test',
        CaseType: 1,
        QuoteId: 1,
        Margin: 2500,
        Total: 4000,
        Priority: 2,
        EstimatedInvoiceDate: null,
      },
      {
        Id: 2,
        ClientId: 1022,
        ProspectId: null,
        CreatedOn: new Date(),
        CreatedBy: 1,
        Description: 'Draft a Patent Application',
        OpportunityStatus: {
          OpportunityStatusId: 4,
          OpportunityStatusLabel: 'Confirmed',
          TimeLinePosition: 3,
        },
        ResponsibleEmployee: "LUCO",
        Supervisor: 2,
        Remarks: 'Small remarks to make a demo',
        CaseType: 2,
        QuoteId: null,
        Margin: 5000,
        Total: 10000,
        Priority: 1,
        EstimatedInvoiceDate: null,
      },
    ];

    this.crmSvc.getOpportunityStatuses().subscribe((res) => {
      this.OpportunityStatuses = res;
    }, (err) => {}
    , () => {
      this.OpportunityStatuses.forEach((status) => {
        this.OpportunityList.push(this.getOpportunitiesByStatus(status.OpportunityStatusId));
      });
    }
    );
  }

  getOpportunitiesByStatus(statusId: number): Opportunity[] {
    return this.Opportunities.filter((o) => o.OpportunityStatus.OpportunityStatusId === statusId);
  }

  onTaskDragStart(e: DxSortableTypes.DragStartEvent) {
    console.log(e);
    e.itemData = e.fromData[e.fromIndex];
  }

  onTaskDrop(e: DxSortableTypes.AddEvent) {
    console.log(e);
    e.fromData.splice(e.fromIndex, 1);
    e.toData.splice(e.toIndex, 0, e.itemData);
  }

  onListReorder(e: DxSortableTypes.ReorderEvent) {
    const list = this.Opportunities.splice(e.fromIndex, 1)[0];
    this.Opportunities.splice(e.toIndex, 0, list);

    const status = this.OpportunityStatuses.splice(e.fromIndex, 1)[0];
    this.OpportunityStatuses.splice(e.toIndex, 0, status);
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxSortableModule,
    DxScrollViewModule,
    OpportunityCardModule,
  ],
  providers: [],
  exports: [CrmClientsOverviewOpportunitiesComponent],
  declarations: [CrmClientsOverviewOpportunitiesComponent],
})
export class CrmClientsOverviewOpportunitiesModule {}

import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DxChartComponent, DxChartModule, DxTabPanelModule, DxTabsModule } from 'devextreme-angular';
import { ItemClickEvent } from 'devextreme/ui/tab_panel';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';

@Component({
  selector: 'time-realized-to-target-graph',
  templateUrl: './time-realized-to-target-graph.component.html',
  styleUrls: ['./time-realized-to-target-graph.component.scss'],
})
  export class TimeRealizedToTargetGraphComponent implements OnInit, OnChanges {
  @ViewChild('RealizedTargetChart') chart: DxChartComponent;

  @Input() EmployeeId: number;
  @Input() StartDate: Date;
  @Input() EndDate: Date;
  @Input() TitlePeriod: string = '';
  @Input() ArgumentField: string = 'Month';

  ChartData: any;

  ChartTitle: string = "Invoiced Time: ";

  Title: string = '';

  selectedTabTitle: string = 'Invoiced Time';
  selectedTabId: number = 1;

  constructor(private TimeReportingSvc: TimeReportingService) {}

  ngOnInit() {
    this.refreshData();
  }

  refreshData() {
    this.chart.instance.showLoadingIndicator();
    this.TimeReportingSvc.getBilledTimeTrack(
      this.EmployeeId,
      this.getDateOnly(this.StartDate),
      this.getDateOnly(this.EndDate)
    ).subscribe((data) => {
      this.ChartData = data;
    },
    (error) => {
      console.error(error);
    },
    () => {
      this.chart.instance.hideLoadingIndicator();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.refreshData();
    }
  }

  getDateOnly(date: Date): string {
    return date.toDateString();
  }
}

@NgModule({
  declarations: [TimeRealizedToTargetGraphComponent],
  exports: [TimeRealizedToTargetGraphComponent],
  imports: [CommonModule, DxChartModule, DxTabPanelModule],
})
export class TimeRealizedToTargetGraphModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from 'src/app/types/invoicing/invoice';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class CreditCollectService {
  url = apiUrl + 'api/creditcollect';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  getAmountDueTotals() {
    return this.http.get<any>(`${this.url}/AmountDue/Totals`);
  }

  getAmountToPayTotals() {
    return this.http.get<any>(`${this.url}/AmountToPay/Totals`);
  }

  prepareMailReminder(
    invoiceHeaderId: number,
    recipientId: number,
    ccIds: number[]
  ): Observable<any> {
    return this.http.post(`${apiUrl}api/CreditCollect/PrepareReminder`, {
      InvoiceHeaderId: invoiceHeaderId,
      ContactRecipientId: recipientId,
      ContactCCRecipientsIds: ccIds,
    });
  }

  prepareCollectiveMailReminder(
    clientId: number,
    recipientId: number,
    ccIds: number[]
  ): Observable<any> {
    return this.http.post(`${apiUrl}api/CreditCollect/PrepareCollectiveReminder`, {
      ClientId: clientId,
      ContactRecipientId: recipientId,
      ContactCCRecipientsIds: ccIds,
    });
  }

  prepareStatementOfAccount(
    clientId: number,
    recipientId: number,
    ccIds: number[]
  ): Observable<any> {
    return this.http.post(
      `${apiUrl}api/CreditCollect/DraftStatementOfAccount`,
      {
        ClientId: clientId,
        ContactRecipientId: recipientId,
        ContactCCRecipientsIds: ccIds,
      }
    );
  }

  setInvoiceAsPaid(invoiceHeaderId: number, paidOn: Date): Observable<Invoice> {
    return this.http.put<Invoice>(
      `${apiUrl}api/CreditCollect/SetInvoiceAsPaid`,
      {
        InvoiceHeaderId: invoiceHeaderId,
        PaidOn: paidOn,
      }
    );
  }

  setCostStatus(costHeaderId: number, status: number): Observable<Invoice> {
    return this.http.put<Invoice>(`${apiUrl}api/CreditCollect/SetCostStatus`, {
      CostHeaderId: costHeaderId,
      CostStatusId: status,
    });
  }
}

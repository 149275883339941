<div class="card dx-card">
  <div
    class="card-priority"
    [ngClass]="'priority-' + opportunity.Priority"
  ></div>
  <div class="card-subject">{{ opportunity.Description }}</div>
  <div class="card-assignee">{{
    opportunity.ResponsibleEmployee
  }}</div>
  <div class="card-assignee">Margin: {{
    opportunity.Margin
    }}</div>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Invoice } from 'src/app/types/invoicing/invoice';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class PeppolService {
  url = apiUrl + 'api';

  constructor(private http: HttpClient) {}

  payerHasPeppol(InvoiceHeaderId: number): Observable<boolean> {
    let params = new HttpParams().set('InvoiceHeaderId', InvoiceHeaderId.toString());

    return this.http.get<any>(
      `${this.url}/CRM/Client/Financial/GetPeppolActivated`,
      { params }
    ).pipe<boolean>(map((data) => data.PeppolActivated));
  }

  sendInvoiceToPeppol(InvoiceHeaderId: number): Observable<boolean> {

    return this.http.post<any>(
      `${this.url}/Invoice/InvoiceHeader/PEPPOL/Send`, {
        InvoiceHeaderId: InvoiceHeaderId,
      }
    ).pipe<boolean>(map((data) => data.Success));
  }
}

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#DetailForm' }"
  [(visible)]="serviceLoading"
  [showIndicator]="true"
  [showPane]="true"
  [hideOnOutsideClick]="false"
/>
<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#DetailForm' }"
  [(visible)]="finalizeLoading"
  [showIndicator]="true"
  [showPane]="true"
  [hideOnOutsideClick]="false"
/>

<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="getTitleText()"
  (visibleChange)="handleVisible($event)"
  width="90%"
  id="InvoiceForm"
  (onShown)="onShownEvent()"
>
  <dx-toolbar toolbar="top" location="right">
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="auto"
      [options]="cancelDraftButtonOptions"
      [visible]="!isFinalized()"
    />
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="auto"
      [visible]="!isFinalized()"
      [options]="previewButtonOptions"
    />
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="auto"
      [options]="editButtonOptions"
      [visible]="!isFinalized()"
    />
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="auto"
      [options]="reloadButtonOptions"
    />
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="auto"
      [options]="finalizeButtonOptions"
      [visible]="!isFinalized()"
      [disabled]="finalizeLoading"
    />
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="auto"
      [options]="creditButtonOptions"
      [visible]="isFinalized()"
    />
    <dxi-item
    location="after"
    widget="dxButton"
    locateInMenu="auto"
    [options]="sendByPeppolButtonOptions"
    [visible]="sendAbleByPeppol()"
    />
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="auto"
      [options]="downloadButtonOptions"
      [visible]="BlobFile !== undefined"
    />
  </dx-toolbar>
  <dx-scroll-view class="view-wrapper-scroll">
    <div class="view-wrapper">
      <div class="panels">
        <div class="left" id="leftPanel">
          <div *ngIf="BlobFile" style="height: 100%" id="pdfpreview">
            <pdf-preview style="height: 90%" [BlobFile]="BlobFile" />
          </div>
          <div *ngIf="!BlobFile">
            <span>No document found.</span>
          </div>
        </div>
        <div class="right" id="rightPart">
          <dx-form [(formData)]="Invoice">
            <dxi-item
              itemType="group"
              cssClass="second-group"
              [colCount]="2"
              caption="General"
              [disabled]="isFinalized()"
            >
              <dxi-item
                dataField="InvoiceNumber"
                [label]="{ text: 'InvoiceNumber', showColon: true }"
                editorType="dxTextBox"
              />
              <dxi-item
                dataField="InvoiceDate"
                editorType="dxDateBox"
                [editorOptions]="{
                  validationMessageMode: 'always',
                  acceptCustomValue: false,
                  openOnFieldClick: true
                }"
              />
              <dxi-item
                dataField="PayerId"
                [label]="{ text: 'Payer' }"
                editorType="dxLookup"
                [editorOptions]="{
                  dataSource: clients,
                  displayExpr: 'LegalName',
                  valueExpr: 'NameId',
                  searchEnabled: true,
                  validationMessageMode: 'always',
                  onValueChanged: onPayerChanged,
                }"
              />
              <dxi-item
                dataField="TemplateId"
                editorType="dxLookup"
                [label]="{ text: 'Template', showColon: true }"
                [editorOptions]="{
                dataSource: templates,
                displayExpr: 'TemplateLabel',
                valueExpr: 'TemplateId',
                searchEnabled: true,
                onValueChanged: onTemplateIdChanged,
              }"
              />
            </dxi-item>
            <dxi-item
              itemType="group"
              cssClass="second-group"
              [colCount]="2"
              caption="Peppol"
              [visible]="ClientHasPeppol"
            >
              <dxi-item [colSpan]="2">
                <div *dxTemplate="let data of 'invoiceTemplate'">
                  <span>Client active on PEPPOL: <i class="dx-icon-isnotblank" [style]="peppolActive()"></i></span>
                </div>
              </dxi-item>
              <dxi-item name="PeppolStatus" [colSpan]="1">
                <dxo-label text="Peppol Status" />
                <div *dxTemplate="let data of 'invoiceTemplate'">
                  <dx-text-box
                    [readOnly]="true"
                    [value]="PeppolStatus"></dx-text-box>
                </div>
              </dxi-item>
              <dxi-item name="PeppolStatus" [colSpan]="1">
                <dxo-label text="Peppol Status" />
                <div *dxTemplate="let data of 'invoiceTemplate'">
                  <dx-text-box
                    [readOnly]="true"
                    [value]="PeppolLastActivity.toDateString()"></dx-text-box>
                </div>
              </dxi-item>
            </dxi-item>
            <dxi-item
              itemType="group"
              cssClass="second-group"
              [colCount]="4"
              caption="Financial"
            >
              <dxi-item
                dataField="InvoiceHeaderCategoryId"
                [label]="{ text: 'Invoice Category', showColon: true }"
                [colSpan]="2"
                editorType="dxLookup"
                [disabled]="isFinalized()"
                [editorOptions]="{
                dataSource: invoiceHeaderCategories,
                displayExpr: 'Label',
                valueExpr: 'InvoiceHeaderCategoryId',
                searchEnabled: true,
              }"
              />
              <dxi-item
                [label]="{ text: 'Amount' }"
                [colSpan]="2"
                dataField="NonVatAmount"
                [disabled]="true"
                editorType="dxNumberBox"
                [editorOptions]="{
                  validationMessageMode: 'always',
                  format: { type: 'currency', precision: 2 }
                }"
              />
              <dxi-item
                [label]="{ text: 'VAT' }"
                [colSpan]="2"
                dataField="VatAmount"
                [disabled]="true"
                editorType="dxNumberBox"
                [editorOptions]="{
                  validationMessageMode: 'always',
                  format: { type: 'currency', precision: 2 }
                }"
              />
              <dxi-item
                [label]="{ text: 'Amount (VAT incl)' }"
                [colSpan]="2"
                dataField="AmountTotal"
                editorType="dxNumberBox"
                [disabled]="true"
                [editorOptions]="{
                  validationMessageMode: 'always',
                  format: { type: 'currency', precision: 2 }
                }"
              />
              <dxi-item
                dataField="CurrencyId"
                [colSpan]="2"
                editorType="dxLookup"
                [visible]="isFinalized()"
                [label]="{ text: 'Currency' }"
                [disabled]="isFinalized()"
                [editorOptions]="{
                  dataSource: currencies,
                  displayExpr: 'CurrencyLabel',
                  valueExpr: 'CurrencyId',
                  searchEnabled: true,
                  validationMessageMode: 'always'
                }"
              />
              <dxi-item
                dataField="IsVatPayed"
                editorType="dxCheckBox"
                [visible]="isFinalized()"
                [colSpan]="1"
                [label]="{ text: 'VAT Payed' }"
              />
              <dxi-item
                dataField="IsPayed"
                editorType="dxCheckBox"
                [visible]="isFinalized()"
                [colSpan]="1"
                [label]="{ text: 'Paid?' }"
              />
              <dxi-item
                dataField="PaidOn"
                editorType="dxDateBox"
                [disabled]="false"
                [visible]="isFinalized()"
                [colSpan]="2"
                [label]="{ text: 'Paid On' }"
                [editorOptions]="{
                  validationMessageMode: 'always',
                  acceptCustomValue: false,
                  openOnFieldClick: true
                }"
              >
                <dxi-validation-rule
                  type="custom"
                  message="Required"
                  [validationCallback]="isPaid"
                  [reevaluate]="true"
                />
              </dxi-item>
              <dxi-item
                dataField="Subsidy"
                editorType="dxCheckBox"
                [visible]="isFinalized()"
                [colSpan]="1"
                [label]="{ text: 'Is Subsidy' }"
              />
              <dxi-item
                dataField="IsInternalError"
                editorType="dxCheckBox"
                [visible]="isFinalized()"
                [colSpan]="1"
                [label]="{ text: 'Is Internal Error' }"
              />
            </dxi-item>
            <dxi-item
              itemType="group"
              cssClass="second-group"
              [colCount]="2"
              caption="Details"
            >
              <dxi-item
                dataField="InvoiceDetails"
                template="invoiceDetailsOverview"
              />
              <div *dxTemplate="let data of 'invoiceDetailsOverview'">
                <finance-draft-invoice-details
                  [invoiceHeaderId]="Invoice.InvoiceHeaderId"
                  [ReadOnly]="isFinalized()"
                />
              </div>
            </dxi-item>
          </dx-form>
        </div>
      </div>
      <credit-lines-selection-form
        *ngIf="creditLineSelectionFormVisible != false"
        [visible]="creditLineSelectionFormVisible"
        [invoiceHeaderId]="Invoice.InvoiceHeaderId"
        (save)="onSavedCreditLinesSelectionForm()"
        [isReopened]="reopensCharge"
      />

      <dx-popup
        #warningPopup
        [(visible)]="showWarningPopupReopenCharge"
        width="400"
        height="auto"
        title="Warning, invoice already credited"
      >
        <p>
          Do you want to reopen the charges that will be credited to be invoiced
          afterwards ?
        </p>
        <div class="warning-buttons">
          <dx-button text="Yes" (onClick)="yesReopenCharge()" />
          <dx-button text="No" (onClick)="noDontReopenCharge()" />
        </div>
      </dx-popup>

      <dx-popup
        #warningPopup
        [(visible)]="showWarningRegenerateInvoice"
        width="400"
        height="auto"
        title="Warning, are you sure that you want to regenerate the invoice ?"
      >
        <p>
          Regenerate the invoice will overwrite your manual changes. Do you
          still want to continue ?
        </p>
        <div class="warning-buttons">
          <dx-button text="Yes" (onClick)="yesRegenerate()" />
          <dx-button text="No" (onClick)="noRegenerate()" />
        </div>
      </dx-popup>
    </div>
  </dx-scroll-view>

  <dx-load-panel
    [visible]="pdfDocumentLoading"
    [message]="'Loading...'"
    [position]="{ of: '#leftPanel' }"
    [showIndicator]="true"
  />
</form-popup>

import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DxDataGridModule, DxTabPanelModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'crm-clients-overview-portfolio',
  templateUrl: './crm-clients-overview-portfolio.component.html',
  styleUrls: ['./crm-clients-overview-portfolio.component.scss'],
})
export class CrmClientsOverviewPortfolioComponent implements OnInit, OnChanges {
  @Input() ClientId: number;

  url: string = environment.CalystaApiBaseURL;
  DataSource: any;

  ShowClosedCases: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ClientId) {
      this.createDataSource();
    }
  }

  ngOnInit() {
    if (this.ClientId != null) this.createDataSource();
  }

  createDataSource() {
    this.DataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}api/CRM/Client/Portfolio/GetAllCases`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = {
              ClientId: this.ClientId,
              ShowClosedCases: this.ShowClosedCases,
            };
          }
        },
      }),
    });
  }
}

@NgModule({
  imports: [CommonModule, DxDataGridModule, DxTabPanelModule],
  providers: [],
  exports: [CrmClientsOverviewPortfolioComponent],
  declarations: [CrmClientsOverviewPortfolioComponent],
})
export class CrmClientsOverviewPortfolioModule {}

<div class="cards kpi">
  <financial-valuta-kpi
    [total]="totals.TotalAmountDue"
    [title]="'Total Amount Due (VAT incl.)'"
    [detailTitle]="'(excl.)'"
    [detailTotal]="totals.TotalAmountDueNonVat"
  />
  <financial-valuta-kpi
    [total]="totals.TotalAmountOverdue"
    [title]="'Total Amount Overdue (VAT incl.)'"
    [detailTitle]="'(excl.)'"
    [detailTotal]="totals.TotalAmountOverdueNonVat"
  />
  <financial-number-kpi
    [total]="totals.InvoicesDue"
    [title]="'# Invoices Due'"
  />
  <financial-number-kpi
    [total]="totals.InvoicesOverdue"
    [title]="'# Invoices Overdue'"
  />
</div>
<div class="separator"></div>
<div class="amountdueGrid">
  <dx-data-grid
    [dataSource]="amountDueDataSource"
    height="80vh"
    [rowAlternationEnabled]="true"
  >
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxi-column dataField="ClientId" caption="Client ID" [visible]="false">
    </dxi-column>
    <dxi-column
      dataField="Client"
      caption="Client"
      [sortIndex]="1"
      sortOrder="asc"
      [allowSearch]="true"
    >
    </dxi-column>
    <dxi-column dataField="PaymentTerms" caption="Payment Terms" [allowSearch]="false"/>
    <dxi-column dataField="InvoicesDue" caption="# Invoices Due" [allowSearch]="false" />
    <dxi-column dataField="InvoicesOverdue" caption="# Invoices Overdue" [allowSearch]="false"/>
    <dxi-column dataField="AmountDueNonVat" caption="Amount Due (excl.)" [allowSearch]="false">
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column dataField="AmountDue1" caption="Amount Due" [allowSearch]="false">
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column
      dataField="AmountOverDueNonVat"
      caption="Amount Overdue (excl.)"
      [allowSearch]="false"
    >
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column dataField="AmountOverDue" caption="Amount Overdue" [allowSearch]="false">
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column type="buttons" [allowSearch]="false">
      <dxi-button
        icon="fa-solid fa-envelope-open-text"
        hint="Prepare statement of account"
        [onClick]="openMailPopupForStatement"
      />
      <dxi-button
      [visible]="HasOverDue"
      icon="fa-solid fa-paper-plane"
      [onClick]="openMailPopupForCollectiveReminder"
      hint="Prepare reminder(s)"
    />
    </dxi-column>

    <dxo-master-detail [enabled]="true" template="detail" />
    <div *dxTemplate="let details of 'detail'">
      <finance-creditcollect-amoundue-details
        [clientId]="details.data.ClientId"
      />
    </div>
  </dx-data-grid>
</div>

<prepare-reminder-contacts-popup
  [(visible)]="showMailPopup"
  [clientId]="selectedClient.ClientId"
  (confirm)="prepareMail($event)"
  [confirmLoading]="loading"
/>

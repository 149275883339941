<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="{{ ClientName }}" />
    <div class="separator"></div>
    <dx-tab-panel>
      <dxi-item title="General" [visible]="true">
        <crm-clients-overview-general [ClientId]="this.PatriciaNameId"></crm-clients-overview-general>
      </dxi-item>
      <dxi-item title="Portfolio" [visible]="true">
        <crm-clients-overview-portfolio [ClientId]="this.PatriciaNameId"></crm-clients-overview-portfolio>
      </dxi-item>
      <dxi-item title="Deadlines" [visible]="true">
        <crm-clients-overview-deadlines [clientId]="this.PatriciaNameId"></crm-clients-overview-deadlines>
      </dxi-item>
      <dxi-item title="Documents" [visible]="true">
        <div class="content" style="height: 80vh;">
          <crm-clients-overview-documents [PatriciaNameId]="this.PatriciaNameId"></crm-clients-overview-documents>
        </div>
      </dxi-item>
      <dxi-item title="Financial" [visible]="true">
        <crm-clients-overview-financial [PatriciaNameId]="this.PatriciaNameId"></crm-clients-overview-financial>
      </dxi-item>
      <dxi-item title="Notes" [visible]="true">
        <crm-clients-overview-notes [PatriciaNameId]="this.PatriciaNameId"></crm-clients-overview-notes>
      </dxi-item>
      <dxi-item title="Opportunities" [visible]="true">
        <crm-clients-overview-opportunities [ClientId]="this.PatriciaNameId"></crm-clients-overview-opportunities>
      </dxi-item>
    </dx-tab-panel>
  </div>
</dx-scroll-view>

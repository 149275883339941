import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { DxButtonModule, DxFormModule, DxScrollViewModule, DxTextAreaModule, DxTextBoxModule, DxToolbarModule, DxValidationGroupModule, DxValidatorModule } from 'devextreme-angular';

@Component({
  selector: 'crm-clients-overview-notes',
  templateUrl: './crm-clients-overview-notes.component.html',
  styleUrls: ['./crm-clients-overview-notes.component.scss']
})
export class CrmClientsOverviewNotesComponent implements OnInit {

  @Input() PatriciaNameId: number;

  noteText: string = '';

  items: any[] = [];

  constructor() { }

  ngOnInit() {
  }

  add() {
    console.log(this.noteText);
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxFormModule,
    DxTextBoxModule,
    DxValidationGroupModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxToolbarModule,
    DxScrollViewModule,
    DxButtonModule,
  ],
  providers: [],
  exports: [CrmClientsOverviewNotesComponent],
  declarations: [CrmClientsOverviewNotesComponent],
})
export class CrmClientsOverviewNotesModule {}

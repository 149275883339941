import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FinanceCreditcollectAmoundueDetailsModule } from './finance-creditcollect-amoundue-details/finance-creditcollect-amoundue-details.component';
import { FinancialValutaKpiModule } from 'src/app/components/utils/financial-valuta-kpi/financial-valuta-kpi.component';
import { FinancialNumberKpiModule } from 'src/app/components/utils/financial-number-kpi/financial-number-kpi.component';
import { CreditCollectService } from 'src/app/services/creditcollect/creditcollect.service';
import notify from 'devextreme/ui/notify';
import { PrepareReminderContactsPopupModule } from 'src/app/components/finance/finance-credit-collect/invoices-out/prepare-reminder-contacts-popup/prepare-reminder-contacts-popup.component';

@Component({
  selector: 'finance-creditcollect-amountdue',
  templateUrl: './finance-creditcollect-amountdue.component.html',
  styleUrls: ['./finance-creditcollect-amountdue.component.scss'],
})
export class FinanceCreditcollectAmountdueComponent implements OnInit {
  amountDueDataSource: any;
  totals: any;

  url = environment.CalystaApiBaseURL + 'api/';
  MailPopupType = null;

  showMailPopup = false;

  selectedClient: any = {};
  loading: boolean;

  constructor(private creditcollectSvc: CreditCollectService) {}

  ngOnInit() {
    this.amountDueDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ClientId',
        loadUrl: `${this.url}CreditCollect/AmountDue/All`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.totals = this.creditcollectSvc.getAmountDueTotals().subscribe(
      (data) => {
        this.totals = data.Totals;
      },
      (err) => {
        const errorMessage: string | null =
          err?.error?.Errors?.GeneralErrors[0];
        notify(errorMessage ?? 'Unknown error', 'Error finalizing draft', 3000);
      },
      () => {}
    );
  }

  openMailPopupForStatement = (e) => {
    this.selectedClient = e.row.data;
    this.showMailPopup = true;
    this.MailPopupType = 'Statement';
  };

  openMailPopupForCollectiveReminder = (e) => {
    this.selectedClient = e.row.data;
    this.showMailPopup = true;
    this.MailPopupType = 'CollectiveReminder';
  };

  prepareMail = (e: { recipientId: number; ccIds: number[] }) => {
    this.loading = true;
    const id = this.selectedClient.ClientId;

    if(this.MailPopupType === 'Statement') {
    this.creditcollectSvc
      .prepareStatementOfAccount(id, e.recipientId, e.ccIds)
      .subscribe({
        next: (x) => {
          this.loading = false;
          this.showMailPopup = false;
          notify(
            'Mail prepared in your draft folder in your mailbox',
            'success',
            5000
          );
        },
        error: (err) => {
          const errorMessage: string | null =
            err?.error?.Errors?.GeneralErrors[0];
          notify(errorMessage ?? 'Unknown error', 'error', 3000);
        },
      });
    } else if (this.MailPopupType === 'CollectiveReminder') {
      this.creditcollectSvc
        .prepareCollectiveMailReminder(id, e.recipientId, e.ccIds)
        .subscribe({
          next: (x) => {
            this.loading = false;
            this.showMailPopup = false;
            notify(
              'Mail prepared in your draft folder in your mailbox',
              'success',
              5000
            );
          },
          error: (err) => {
            const errorMessage: string | null =
              err?.error?.Errors?.GeneralErrors[0];
            notify(errorMessage ?? 'Unknown error', 'error', 3000);
          },
        });
    }
  };

  HasOverDue = (e: any) => {
    return e.row.data.AmountOverDueNonVat > 0;
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    FinanceCreditcollectAmoundueDetailsModule,
    FinancialValutaKpiModule,
    FinancialNumberKpiModule,
    PrepareReminderContactsPopupModule,
  ],
  providers: [],
  declarations: [FinanceCreditcollectAmountdueComponent],
  exports: [FinanceCreditcollectAmountdueComponent],
})
export class FinanceCreditcollectAmountdueModule {}

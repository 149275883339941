import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  DxDataGridModule,
  DxFormModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { PDFPreviewModule } from 'src/app/components/library/pdf-preview/pdf-preview.component';
import { InvoiceService } from 'src/app/services/invoicing/Invoice/InvoiceService.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'crm-clients-overview-financial',
  templateUrl: './crm-clients-overview-financial.component.html',
  styleUrls: ['./crm-clients-overview-financial.component.scss'],
})
export class CrmClientsOverviewFinancialComponent implements OnInit, OnChanges {
  DataSource: DataSource;

  url: string = environment.CalystaApiBaseURL;

  @Input() PatriciaNameId: number;

  loadingVisible: boolean = false;
  previewInvoiceVisible: boolean = false;
  previewInvoiceNumber: string = null;
  previewFileBlob: any;

  constructor(private InvoiceSvc: InvoiceService) {
    this.onPreview = this.onPreview.bind(this);
  }

  ngOnInit() {
    if (this.PatriciaNameId) {
      this.createDataSource();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.PatriciaNameId) {
      this.createDataSource();
    }
  }

  createDataSource() {
    this.DataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceHeaderId',
        loadUrl: `${this.url}api/CRM/Client/Financial/GetInvoicesAndMargin`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = {
              ClientId: this.PatriciaNameId,
            };
          }
        },
      }),
    });
  }

  onPreview(e) {
    this.loadingVisible = true;
    this.InvoiceSvc.getPdfDocument(e.row.data.InvoiceHeaderId).subscribe((data) => {
      this.previewFileBlob = data;
    }, (error) => {
      this.loadingVisible = false;
      notify('Error while fetching document preview', 'error', 3000);
    },
    () => {
      this.loadingVisible = false;
      this.previewInvoiceVisible = true;
      this.previewInvoiceNumber = e.row.data.InvoiceNumber;
    });
  }
}

@NgModule({
  imports: [CommonModule, DxFormModule, DxTextBoxModule, DxDataGridModule, DxLoadPanelModule, DxPopupModule, PDFPreviewModule],
  providers: [],
  exports: [CrmClientsOverviewFinancialComponent],
  declarations: [CrmClientsOverviewFinancialComponent],
})
export class CrmClientsOverviewFinancialModule {}

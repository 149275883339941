<div id="page">
  <dx-responsive-box>
    <dxi-row [ratio]="1"></dxi-row>
    <dxi-row [ratio]="2"></dxi-row>
    <dxi-row [ratio]="1"></dxi-row>

    <dxi-col [ratio]="1"></dxi-col>
    <dxi-col [ratio]="1"></dxi-col>
    <dxi-col [ratio]="1"></dxi-col>
    <dxi-col [ratio]="1"></dxi-col>

    <!-- Move this to a seperate chart component -->
    <dxi-item>
      <dxi-location [row]="0" [col]="2" [colspan]="1"></dxi-location>
      <dx-pie-chart id="IPAssetPie" [dataSource]="IPAssets" title="IP Assets">
        <dxi-series argumentField="CaseType" valueField="Count">
          <dxo-label [visible]="true"></dxo-label>
        </dxi-series>
        <dxo-legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="right"
          [rowCount]="2"
        ></dxo-legend>
      </dx-pie-chart>
    </dxi-item>

    <!-- Move this to a seperate chart component -->
    <dxi-item>
      <dxi-location [row]="0" [col]="3" [colspan]="1"></dxi-location>
      <dx-pie-chart
        id="IPServicePie"
        [dataSource]="IPServices"
        title="Services"
      >
        <dxi-series argumentField="CaseType" valueField="Count">
          <dxo-label [visible]="true"></dxo-label>
        </dxi-series>
        <dxo-legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="right"
          [rowCount]="2"
        ></dxo-legend>
      </dx-pie-chart>
    </dxi-item>

    <dxi-item>
      <dxi-location [row]="1" [col]="2" [colspan]="2"></dxi-location>
      <dx-chart
        title="Filing trend"
        id="FilingChart"
        [dataSource]="FilingsByYear"
      >
        <dxi-series argumentField="Year" valueField="Count" type="bar">
        </dxi-series>
        <dxo-argument-axis argumentType="string"> </dxo-argument-axis>
      </dx-chart>
    </dxi-item>
    <dxi-item>
      <dxi-location [row]="2" [col]="3" [colspan]="1"></dxi-location>
      <dx-pie-chart
        id="IPServicePie"
        [dataSource]="IPServices"
        title="Services"
      >
        <dxi-series argumentField="CaseType" valueField="Count">
          <dxo-label [visible]="true"></dxo-label>
        </dxi-series>
        <dxo-legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="right"
          [rowCount]="2"
        ></dxo-legend>
      </dx-pie-chart>
    </dxi-item>
  </dx-responsive-box>
</div>

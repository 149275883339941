<dx-tab-panel
  tabsPosition="left">
  <dxi-item title="All" [visible]="true">
    <dx-data-grid [dataSource]="DataSource" height="80vh" id="portfolioGrid">
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxi-column dataField="CaseId" [visible]="false"></dxi-column>
      <dxi-column
      dataField="CreatedOn"
      caption="Creation Date"
      dataType="date"
      sortOrder="desc"
      width="8%"
    ></dxi-column>
      <dxi-column dataField="CaseNumber"></dxi-column>
      <dxi-column dataField="CatchWord"> </dxi-column>
      <dxi-column
        dataField="CaseTypeId"
        calculateDisplayValue="CaseTypeLabel"
        caption="Type"
      ></dxi-column>
      <dxi-column
      dataField="CountryId"
      calculateDisplayValue="CountryLabel"
      caption="Country"
    ></dxi-column>
      <dxi-column
        dataField="StatusId"
        calculateDisplayValue="StatusLabel"
        caption="Status"
      ></dxi-column>
      <dxi-column
      dataField="ApplicationTypeId"
      calculateDisplayValue="ApplicationTypeLabel"
      caption="Application Type"
    ></dxi-column>
    <dxi-column
    dataField="ServiceLevelId"
    calculateDisplayValue="ServiceLevelLabel"
    caption="Service Level">
    </dxi-column>
    </dx-data-grid>
  </dxi-item>
  <dxi-item title="Design"></dxi-item>
  <dxi-item title="Domain"></dxi-item>
  <dxi-item title="Patent"></dxi-item>
  <dxi-item title="Trademark"></dxi-item>
  <dxi-item title="Services"></dxi-item>
</dx-tab-panel>

<dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    class="grid"
    height="80vh"
    [dataSource]="dataSource"
    [remoteOperations]="true"
    dataRowTemplate="dataRowTemplate"
    [rowAlternationEnabled]="true"
  >
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
    <dxo-load-panel [enabled]="true" />
    <dxo-sorting mode="multiple" />
    <dxo-editing
      mode="popup"
      [allowAdding]="false"
      [allowUpdating]="false"
      [allowDeleting]="false"
    />

    <dxo-search-panel [visible]="true" width="20vw" />

    <dxo-toolbar>
      <dxi-item location="before"
                locateInMenu="never"
                widget="dxButton"
          [options]="{
            text: showHideRenewalsButtonText(),
            stylingMode: 'text',
            onClick: showHideRenewals
          }"
        />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
      <dxi-item location="after" name="searchPanel" locateInMenu="never" />
    </dxo-toolbar>

    <dxi-column type="buttons">
      <dxi-button
        hint="See in patricia"
        icon="fas fa-horse"
        [onClick]="openPatricia"
      />
    </dxi-column>
    <dxi-column dataField="CaseNumber" caption="Case" width="8%" />
    <dxi-column dataField="InternalStatusText" caption="Status" width="60%"/>
    <dxi-column dataField="ResponsiblePerson" caption="Responsible" />
    <dxi-column
      dataField="InternalTermDate"
      caption="Term Date"
      dataType="date"
      sortOrder="asc"
      sortIndex="0"
      width="100px"
    />
    <dxi-column
      dataField="OfficialDeadline"
      caption="Official DL."
      dataType="date"
      width="100px"
    />
    <dxi-column
      dataField="Client"
      caption="Client"
      [visible]="false"
      [allowSearch]="true"
    />

    <ng-container *dxTemplate="let deadline of 'dataRowTemplate'">
      <tr class="main-row" role="row">
        <td rowspan="2" role="gridcell">
          <div class="icon-container"><i [class]="'fas fa-horse'"></i></div>
        </td>
        <td role="gridcell">{{ deadline.data.CaseNumber }}</td>
        <td role="gridcell">{{ deadline.data.InternalStatusText }}</td>
        <td role="gridcell"> {{ deadline.data.ResponsiblePerson}}</td>
        <td role="gridcell">
          {{ deadline.data.InternalTermDate | date : "dd/MM/yyyy" }}
        </td>
        <td role="gridcell">
          {{ deadline.data.OfficialDeadline | date : "dd/MM/yyyy" }}
        </td>
      </tr>
      <tr class="notes-row" role="row">
        <td role="gridcell" colspan="1"></td>
        <td role="gridcell">{{ deadline.data.InternalActionText }}</td>
      </tr>
    </ng-container>
  </dx-data-grid>

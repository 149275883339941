<!-- <dx-form [colCount]="2">
    <dxi-item [colSpan]="1">
      <dxo-label text="VAT Number"></dxo-label>
      <div *dxTemplate="let data of 'data'">
        <dx-text-box [value]="'BE09202020202'" [readOnly]="true"></dx-text-box>
      </div>
    </dxi-item>
    <dxi-item colSpan="1">
      <dxo-label text="Payment Terms:"></dxo-label>
      <div *dxTemplate="let data of 'data'">
        <dx-text-box [value]="'30 days'" [readOnly]="true"></dx-text-box>
      </div>
    </dxi-item>
    <dxi-item colSpan="1">
      <dxo-label text="Amount Due (excl.):"></dxo-label>
      <div *dxTemplate="let data of 'data'">
        <dx-text-box [value]="'€ 1.000,00'" [readOnly]="true"></dx-text-box>
      </div>
    </dxi-item>
    <dxi-item colSpan="1">
      <dxo-label text="Amount OverDue (excl.):"></dxo-label>
      <div *dxTemplate="let data of 'data'">
        <dx-text-box [value]="'€ 1.000,00'" [readOnly]="true"></dx-text-box>
      </div>
    </dxi-item>
</dx-form> -->

<dx-data-grid
  [dataSource]="DataSource" height="80vh" id="InvoicesGrid">
  <dxi-column dataField="InvoiceHeaderId" [visible]="false"></dxi-column>
  <dxi-column dataField="InvoiceDate" caption="Date"></dxi-column>
  <dxi-column dataField="InvoiceNumber"></dxi-column>
  <dxi-column dataField="InvoiceAmount" caption="Amount"></dxi-column>
  <dxi-column dataField="MarginAdmin" caption="Margin Admin"></dxi-column>
  <dxi-column dataField="MarginIntel" caption="Margin Intel"></dxi-column>
  <dxi-column dataField="Margin" caption="Margin"></dxi-column>
  <dxi-column type="buttons">
    <dxi-button name="preview" [visible]="true" [onClick]="onPreview" icon="search"></dxi-button>
  </dxi-column>
</dx-data-grid>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#InvoicesGrid' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>

<dx-popup [(visible)]="previewInvoiceVisible"
          [title]="previewInvoiceNumber"
          [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
      <pdf-preview [BlobFile]="previewFileBlob" [previewExists]="true"></pdf-preview>
  </div>
</dx-popup>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from 'src/app/types/invoicing/invoice';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  url = apiUrl + 'api/Invoice/InvoiceHeader';

  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  getPdfDocument(invoiceHeaderId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('InvoiceHeaderId', invoiceHeaderId.toString());

    return this.http.get(`${this.url}/PdfDocument`, {
      responseType: 'blob', params: params
    });
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, input, NgModule, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'crm-clients-overview-deadlines',
  templateUrl: './crm-clients-overview-deadlines.component.html',
  styleUrls: ['./crm-clients-overview-deadlines.component.scss']
})
export class CrmClientsOverviewDeadlinesComponent implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  dataSource: any;

  @Input() clientId: number;

  url = environment.CalystaApiBaseURL + 'api/';
  hideRenewals: boolean = true;

  constructor() {
   }

  ngOnInit() {
    if(this.clientId) {
      this.loadDataSource();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.PatriciaNameId) {
      this.loadDataSource();
    }
  }

  loadDataSource() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'EventId',
        loadUrl: `${this.url}Patricia/Event/Deadline/Client`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};

            ajaxOptions.data.ClientId = this.clientId;
            ajaxOptions.data.HideRenewals = this.hideRenewals;
          }
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  openPatricia(e) {
    //open new tab on url http://patricia.calysta.eu/pat/#!case-view/ {data.CaseNumber}
    const caseNumber = e.row.data.CaseNumber;
    window.open(
      `http://patricia.calysta.eu/pat/#!case-view/${caseNumber}`,
      '_blank'
    );
    e.event.preventDefault();
  }

  showHideRenewalsButtonText(): string {
    return this.hideRenewals ? 'Show Renewals' : 'Hide Renewals';
  }

  showHideRenewals = () => {
    this.hideRenewals = !this.hideRenewals;
    this.refreshData();
  };
}

@NgModule({
  imports: [CommonModule, DxDataGridModule],
  providers: [],
  exports: [CrmClientsOverviewDeadlinesComponent],
  declarations: [CrmClientsOverviewDeadlinesComponent],
})
export class CrmClientsOverviewDeadlinesModule {}

import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { DxChartModule, DxPieChartModule, DxResponsiveBoxModule } from 'devextreme-angular';
import { TickerCardModule } from 'src/app/components';

@Component({
  selector: 'crm-clients-overview-general',
  templateUrl: './crm-clients-overview-general.component.html',
  styleUrls: ['./crm-clients-overview-general.component.scss']
})
export class CrmClientsOverviewGeneralComponent implements OnInit {

  @Input() ClientId: number;

  constructor() { }

  ngOnInit() {
  }


  IPAssets: IPAsset[] = [{
    CaseType: 'Patent',
    Count: 12,
  }, {
    CaseType: 'Trademark',
    Count: 120,
  }, {
    CaseType: 'Design',
    Count: 40,
  }, {
    CaseType: 'Domain',
    Count: 11,
  }];

  IPServices: IPAsset[] = [{
    CaseType: 'Contracting',
    Count: 12,
  }, {
    CaseType: 'Trademark Opposition',
    Count: 120,
  }, {
    CaseType: 'Opinion',
    Count: 40,
  }, {
    CaseType: 'Domain',
    Count: 11,
  }];

  FilingsByYear: FilingsByYear[] = [{
    Year: 2016,
    Count: 12,
  }, {
    Year: 2017,
    Count: 120,
  }, {
    Year: 2018,
    Count: 40,
  }, {
    Year: 2019,
    Count: 11,
  }];
}

@NgModule({
  imports: [CommonModule, DxPieChartModule, DxResponsiveBoxModule, DxChartModule, TickerCardModule],
  providers: [],
  exports: [CrmClientsOverviewGeneralComponent],
  declarations: [CrmClientsOverviewGeneralComponent],
})
export class CrmClientsOverviewGeneralModule {}


export class IPAsset {
  CaseType: string;
  Count: number;
}

export class FilingsByYear{
  Year: number;
  Count: number;
}



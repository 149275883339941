<dx-data-grid [dataSource]="DataSource" height="80vh" id="documentsGrid">
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-toolbar>
    <dxi-item location="before">
      <dx-button
        [text]="showHideEmailButtonText"
        (onClick)="showHideEmail()"
      ></dx-button>
    </dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
  </dxo-toolbar>
  <dxi-column dataField="DocumentId" [visible]="false"></dxi-column>
  <dxi-column dataField="FileType" cellTemplate="FileTypeTemplate" width="6%">
  </dxi-column>
    <dxi-column
      dataField="CreatedOn"
      caption="Creation Date"
      alignment="Left"
      dataType="date"
      sortOrder="desc"
      width="8%"
    ></dxi-column>
  <dxi-column
    dataField="CaseId"
    calculateDisplayValue="CaseNumber"
    caption="Case"
    alignment="Left"
  ></dxi-column>
  <dxi-column dataField="Name" caption="Name" [visible]="false"> </dxi-column>
  <dxi-column dataField="Description" caption="Description"></dxi-column>
  <dxi-column dataField="DocumentCategoryId" calculateDisplayValue="DocumentCategory" caption="Category"></dxi-column>
  <dxi-column dataField="DocumentSubCategoryId" calculateDisplayValue="DocumentSubCategory" caption="Sub-Category"></dxi-column>
  <dxi-column dataField="VisibleOnPortal" caption="public"></dxi-column>
  <dxi-column type="buttons">
    <dxi-button name="preview" [visible]="true" [onClick]="onPreview" icon="search"></dxi-button>
  </dxi-column>
  <div *dxTemplate="let data of 'FileTypeTemplate'">
    <i class="{{determineFileTypeLogo(data.data.FileType)}}"></i>
  </div>
</dx-data-grid>

<dx-popup [(visible)]="previewDocumentVisible"
          [title]="previewedDocumentName"
          [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
      <pdf-preview [BlobFile]="fileBlob" [previewExists]="true"></pdf-preview>
  </div>
</dx-popup>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#documentsGrid' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>

